<template>
  <div class="scaler absolute" :style="{ left, top, width, height, transform }">
    <div class="fixed top-4 right-4 text-gray-400 text-right">
      {{ slideIndex + 1 || 'x' }} / {{ slides.length }} · 
      <button @click="showAllSlides" class="mr-4 px-1 rounded-lg border border-gray-400 hover:bg-gray-400 hover:text-white">toutes les slides</button>
    </div>
    <div
      v-for="(slide, index) in slides"
      v-show="index == slideIndex"
      :key="index"
      class="section h-full"
      :class="{ active: index == slideIndex }"
      :ref="'d' + index"
      :data-anchor="'d' + index"
    >
      <div
        class="w-full h-full diapositive"
        :class="[{ flat: !prez }, slide.class]"
        v-html="slide.content"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: null,
      left: 0,
      top: 0,
      width: 0,
      height: 0,
      transform: null,
    }
  },
  mounted() {
    this.refreshScaler()
    this.adaptSimpleSlides()
    this.goToCurrentSlide()
  
    document.addEventListener('keydown', this.onKeyDown)
    window.addEventListener('resize', this.refreshScaler)
  },
  methods: {
    showAllSlides() {
      this.$emit("showAllSlides")
    },
    refreshScaler() {
      const referenceWidth = 1600
      const referenceHeight = 900
      const referenceRatio = 16 / 9

      const ratio = { width: 16, height: 9, ratio: 16 / 9 }

      const containerWidth = window.innerWidth
      const containerHeight = window.innerHeight

      const dimensions = {
        width: Math.floor((referenceWidth / referenceRatio) * ratio.ratio),
        height: referenceHeight,
      }

      let scale, scaledWidth, scaledHeight, _left, _top

      if (containerWidth / ratio.width > containerHeight / ratio.height) {
        scale = containerHeight / dimensions.height
      } else {
        scale = containerWidth / dimensions.width
      }

      scaledWidth = dimensions.width * scale
      scaledHeight = dimensions.height * scale

      _left = (containerWidth - scaledWidth) / 2
      _top = (containerHeight - scaledHeight) / 2

      this.transform = 'scale(' + scale + ')'
      this.left = Math.max(_left, 0) + 'px'
      this.top = Math.max(_top, 0) + 'px'
      this.width = referenceWidth + 'px'
      this.height = referenceHeight + 'px'
    },
    onKeyDown(e) {
      e.stopPropagation()

      if (e.key == 'ArrowRight' || e.key == 'ArrowDown') this.goNext()
      if (e.key == 'ArrowLeft' || e.key == 'ArrowUp') this.goPrevious()
    },
    goToCurrentSlide() {
      const el = this.$refs['d' + this.slideIndex]
      if (!el) return

      this.currentSlide = el[0]
    },
    goPreviousSlide() {
      this.$emit("goPrevious")
    },
    goNextSlide() {
      this.$emit("goNext")
    },
    goPrevious() {
      let bullets = []
      if (this.prez) {
        bullets = this.currentSlide.querySelectorAll(
          ':not(.flat) .slide-content-wrapper p, :not(.flat) .slide-content-wrapper li, :not(.flat) .slide-content-wrapper pre'
        )
      }

      let hasFound = false
      for (let i = bullets.length - 1; i >= 0; i--) {
        if (bullets[i].style.opacity == 1) {
          bullets[i].style.opacity = 0
          hasFound = true
          return
        }
      }

      if (!hasFound) this.goPreviousSlide()
    },
    goNext() {
      let bullets = []
      if (this.prez) {
        bullets = this.currentSlide.querySelectorAll(
          ':not(.flat) .slide-content-wrapper p, :not(.flat) .slide-content-wrapper li, :not(.flat) .slide-content-wrapper pre'
        )
      }

      let hasFound = false
      for (let i = 0; i < bullets.length; i++) {
        if (bullets[i].style.opacity == 0) {
          bullets[i].style.opacity = 1
          hasFound = true
          return
        }
      }

      if (!hasFound) this.goNextSlide()
    },
    adaptSimpleSlides() {
      const slides = document.querySelectorAll('.simpleslide')
      for (let index = 0; index < slides.length; index++) {
        const slide = slides[index]

        const slideCopy = slide.cloneNode(true)

        slide.innerHTML = ''

        let title = slideCopy.querySelector('h2')
        if (title) {
          title = title.cloneNode(true)
          slideCopy.querySelector('h2').remove()
          slide.appendChild(title)
        }

        slideCopy.classList = ['slide-content-wrapper']
        slide.appendChild(slideCopy)

        let footnote = slideCopy.querySelector('.footnote')
        if (footnote) {
          const footnoteCopy = footnote.cloneNode(true)
          footnote.remove()
          slide.appendChild(footnoteCopy)
        }
      }
    },
  },
  props: {
    slides: {
      type: [],
      default: '',
    },
    prez: {
      type: Boolean,
      default: false,
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    slideIndex() {
      this.goToCurrentSlide()
    },
  },
}
</script>

<style lang="postcss">

</style>

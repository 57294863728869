<template>
  <div class="p-10">
    <h1 class="text-white">{{ title }}</h1>
    <div class="text-white mb-10">
      <button @click="play" class="mr-4 px-2 py-1 rounded-lg border border-white-400 hover:bg-white hover:text-black">lecture</button>
      <button @click="present" class="px-2 py-1 rounded-lg border border-white-400 hover:bg-white hover:text-black">présenter</button>
    </div>
    <div class="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        ref="slides"
        class="thumbnail"
        :style="transform && { height: scaledHeight }"
      >
        <div
          @click="selectSlide(index)"
          class="section h-full scaler"
          :data-anchor="'d' + index"
          :ref="'d' + index"
          :class="{ active: index == slideIndex }"
          :style="transform && { width, height, transform }"
        >
          <div
            class="w-full h-full diapositive flat"
            :class="[slide.class]"
            v-html="slide.content"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width: 0,
      height: 0,
      transform: null,
      scaledWidth: null,
      scaledHeight: null,
    }
  },
  mounted() {
    this.refreshScaler()
    window.addEventListener('resize', this.refreshScaler)

    this.adaptSimpleSlides()
    this.$nextTick(this.goToCurrentSlide)
  },
  methods: {
    play() {
      this.$emit("play")
    },
    present() {
      this.$emit("present")
    },
    refreshScaler() {
      if (!this.$refs.slides[0]) return

      const referenceWidth = 1600
      const referenceHeight = 900
      const referenceRatio = 16 / 9

      const ratio = { width: 16, height: 9, ratio: 16 / 9 }

      const containerWidth = this.$refs.slides[0].getBoundingClientRect().width
      const containerHeight = containerWidth / referenceRatio

      const dimensions = {
        width: Math.floor((referenceWidth / referenceRatio) * ratio.ratio),
        height: referenceHeight,
      }

      let scale

      if (containerWidth / ratio.width > containerHeight / ratio.height) {
        scale = containerHeight / dimensions.height
      } else {
        scale = containerWidth / dimensions.width
      }

      this.scaledWidth = dimensions.width * scale + 'px'
      this.scaledHeight = dimensions.height * scale + 'px'
      this.transform = 'scale(' + scale + ')'
      this.width = referenceWidth + 'px'
      this.height = referenceHeight + 'px'
    },
    selectSlide(index) {
      this.$emit('selectSlide', index)
    },
    goToCurrentSlide() {
      const el = this.$refs['d' + this.slideIndex]
      if (!el) return
      const currentSlide = el[0]

      if (currentSlide) {
        window.scrollTo({ top: currentSlide.getBoundingClientRect().top - 200 })
      }
    },
    adaptSimpleSlides() {
      const slides = document.querySelectorAll('.simpleslide')
      for (let index = 0; index < slides.length; index++) {
        const slide = slides[index]

        const slideCopy = slide.cloneNode(true)

        slide.innerHTML = ''

        let title = slideCopy.querySelector('h2')
        if (title) {
          title = title.cloneNode(true)
          slideCopy.querySelector('h2').remove()
          slide.appendChild(title)
        }

        slideCopy.classList = ['slide-content-wrapper']
        slide.appendChild(slideCopy)

        let footnote = slideCopy.querySelector('.footnote')
        if (footnote) {
          const footnoteCopy = footnote.cloneNode(true)
          footnote.remove()
          slide.appendChild(footnoteCopy)
        }
      }
    },
  },
  props: {
    slides: {
      type: [],
      default: '',
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="postcss"></style>

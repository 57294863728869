<template>
  <div class="grid grid-cols-12 bg-gray-900" :class="{ 'h-screen': !grid }">
    <div class="col-span-12 relative flex justify-center items-center h-full">
      <Grid
        v-if="grid && slides.length > 0"
        :slides="slides"
        :slideIndex="slideIndex"
        @selectSlide="onSelectSlide"
        @play="play"
        @present="present"
        :title="title"
      />
      <Slideshow
        v-if="!grid && slides.length > 0"
        :prez="prezMode"
        :slides="slides"
        @goPrevious="goPrevious"
        @goNext="goNext"
        @showAllSlides="showAllSlides"
        :slideIndex="slideIndex"
      />
    </div>
  </div>
</template>

<script>
import Slideshow from '@/components/Slideshow.vue'
import Grid from '@/components/Grid.vue'

const CLASS_PATTERN = /\n*class: (.*)/g

const fm = require('front-matter')

const hljs = require('highlight.js/lib/common')

hljs.registerLanguage(
  'php',
  require('highlight.js/lib/languages/php')
)

import 'highlight.js/styles/github.css';

export default {
  components: { Grid, Slideshow },
  data() {
    return {
      content: '',
      slideIndex: null,
      prezMode: false,
      md: null,
      grid: true,
      title: null,
    }
  },
  async mounted() {
    const slug = this.$route.params.slug
    this.content = await fetch(
      'https://bibliodev-presentations-content.netlify.app/presentations/' +
        slug +
        '.md'
    ).then((response) => response.text())

    this.parseMarkdown()

    if ('pres' in this.$route.query) {
      this.prezMode = true
    }

    this.goToSlideOnLoad()

    window.addEventListener('hashchange', this.onHashChange)
  },
  computed: {
    slidesMd() {
      return this.content.split('---')
    },
    slides() {
      if (!this.md) return []

      const slides = []

      for (let i = 1; i < this.slidesMd.length; i++) {
        let s = this.slidesMd[i]

        if (i == 1) {
          s = `---${s}---`
          const infos = fm(s)
          this.title = infos.attributes.title
          continue
        }

        const found = CLASS_PATTERN.exec(s)
        s = s.replace(CLASS_PATTERN, '')

        slides.push({
          class: (found && found[1]) || 'simpleslide',
          content: this.md.render(s),
        })
      }

      return slides
    },
  },
  methods: {
    play() {
      this.grid = false
      this.prezMode = false
    },
    present() {
      this.grid = false
      this.prezMode = true
    },
    onSelectSlide(index) {
      this.slideIndex = index
      this.updateHash()
      this.grid = false
      this.prezMode = false
    },
    updateHash() {
      window.location.hash = 'd' + this.slideIndex
    },
    goToSlideOnLoad() {
      const anchor = this.$route.hash.split('#').pop()

      const regex = new RegExp(/d([0-9]+)/g)
      const match = regex.exec(anchor)

      if (anchor) {
        this.slideIndex = parseInt(match[1])
        this.grid = false
      } else this.slideIndex = 0
    },
    showAllSlides() {
      this.grid = true
    },
    onSlideChange(newIndex) {
      this.slideIndex = newIndex
      window.location.hash = 'd' + this.slideIndex
    },
    goPrevious() {
      if (this.slideIndex == 0) return

      this.slideIndex = this.slideIndex - 1

      this.updateHash()
    },
    goNext() {
      if (this.slideIndex == this.slides.length - 1) return

      this.slideIndex = this.slideIndex + 1

      this.updateHash()
    },
    parseMarkdown() {
      this.md = require('markdown-it')({
        html: true,
        xhtmlOut: true,
        typographer: true,
        linkify: true,
        typographer: true,
      })
        .use(require('markdown-it-highlightjs'), { hljs })
        .use(require('markdown-it-container'), 'footnote')
        .use(require('markdown-it-container'), 'left')
        .use(require('markdown-it-container'), 'right')
    },
  },
  watch: {
    slideIndex() {
      this.updateHash()
    },
  },
}
</script>

<style>
.aspect-16-9 {
  padding-bottom: 56.25%;
  max-height: 100vh;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

import Vue from "vue";
import App from "@/App.vue";
import Presentation from "@/Presentation";
import Redirect from "@/Redirect";
import VueRouter from "vue-router";
import VueFullPage from "vue-fullpage.js";
import "@/assets/global.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Redirect,
  },
  {
    path: "/:slug",
    component: Presentation,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

Vue.config.productionTip = false;

Vue.use(VueFullPage);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
